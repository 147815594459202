import teamImg1 from "../../assets/team-1.jpg";
import teamImg2 from "../../assets/team-2.jpg";
import teamImg3 from "../../assets/team-3.jpg";
export const teams = [
  {
    name: "Alisson Kopp",
    img: teamImg1,
    subTitle: "Administrador e Dev Sênior",
    text: "Fundador da causa, profissional em linguagens nativas. Desenvolvedor Full-Stack, analista de banco de dados.",
  },
  {
    name: "Leonardo Kopp",
    img: teamImg3,
    subTitle: "Administrador e Dev Sênior",
    text: "Co-Fundador da causa, profissional em linguagens web. Desenvolvedor Full-Stack em diferentes áreas.",
  },
  {
    name: "Jaine Rodrigues",
    img: teamImg2,
    subTitle: "RH & Finânceiro",
    text: "Setor importante em qualquer empresa, dedicada as finânças e ao gerenciamento de nossas equipes.",
  },
];
