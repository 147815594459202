const resources = [
  { id: 1, name: "Página de apresentação", description: "Sites que são apenas uma apresentação do seu negócio ou produto." },
  { id: 2, name: "Sites responsivos", description: "Sites que se adaptam perfeitamente ao tamanho da tela do computador, celular ou tablet." },
  { id: 3, name: "Melhoria da experiência do usuário", description: "Estratégias de UX/UI design focadas em otimizar a experiência do usuário e melhorar a usabilidade." },
  { id: 4, name: "Layout Personalizado", description: "O layout é exclusivo e desenvolvido por especialistas. Formas e cores são pensados cuidadosamente para você." },
  { id: 5, name: "Páginas com carregamento rápido", description: "Carregamento das páginas de forma rápida para aumentar o interesse do usuário, desenvolvido em React.js." },
  { id: 6, name: "Hospedagem do site 24h inclusa", description: "Conte com uma hospedagem sem lentidão e 24 horas online, 7 dias da semana." },
  { id: 7, name: "Suporte contínuo", description: "Conte com um time de especialistas para dar suporte às suas nescessidades. Tudo isso feito sob-demanda, quando precisar, e de onde estiver." },
  { id: 8, name: "Personalização total", description: "Desenvolvemos todos os nossos sites de forma personalizada, layouts 100% exclusivo para cada cliente. Contando com a aparência moderna, para transmitir confiança aos visitantes." },
  { id: 9, name: "Domínio próprio incluso", description: "Conte com a criação e configuração de domínios próprios para seu site e emails." },
  { id: 9, name: "Emails com domínio próprio", description: "Utilize o serviço de email com domínio para trazer mais profissionalidade ao seu negócio, exemplo contato@milbytes.com.br" },
];

const validResources = {
  "BASIC PLAN": [1, 2, 3, 4, 5, 6, 7],
  "EMPRESS PLAN": [1, 2, 3, 4, 5, 6, 7, 8, 9],
  "EPIC PLAN": [1, 2, 3, 4, 5, 6, 7],
  "LANDING PLAN": [1, 2, 3, 4, 5, 7, 8],
};

export const pricingPlans = [
  //  {
  //    buttonType: "regular-pricing-btn" ou "blood-pricing-btn" //para definir o formato do botão, 
  //    bloodState: false OU true, //para deixar o card vermelho
  //    alert: "TEXTO EXCLUSIVO",
  //    title: "NOME PLAN",
  //    price: "PREÇO",
  //    time: "TEMPO",
  //    list: generateList("NOME PLAN"),
  //  },

  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    title: "BASIC PLAN",
    price: "137,90",
    time: "mensal",
    list: generateList("BASIC PLAN"),
  },

  {
    buttonType: "blood-pricing-btn",
    bloodState: true,
    title: "EMPRESS PLAN",
    alert: "Mais vendido",
    parcela: "12x ",
    price: "289,90",
    time: "",
    list: generateList("EMPRESS PLAN"),
  },
  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    title: "EPIC PLAN",
    alert: "",
    price: "CONSULTAR",
    list: generateList("EPIC PLAN"),
  },

  {
    buttonType: "regular-pricing-btn",
    bloodState: false,
    title: "LANDING PAGE",
    price: "437,90",
    list: generateList("LANDING PLAN"),
  },
];

function generateList(planName) {
  return resources.map((resource) => {
    return validResources[planName].includes(resource.id) ? `✔️ ${resource.name}` : `❌ ${resource.name}`;
  });
}