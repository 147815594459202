import React from "react";
import {FooterSquareStyled} from "./footer.styled";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";

const FooterSquare = () => {
  return (
    <FooterSquareStyled>
      <h3>MILBYTES</h3>
      <p className="mb-3">milbytes.com.br</p>
      <p className="mb-1">Contato: +55 51 99585-6053</p>
      <p className="mb-1">Email: contato@milbytes.com.br</p>
      <ul>
        <li>
          <FaFacebookF />
        </li>
        <li>
          <FaInstagram />
        </li>
        <li>
          <FaTwitter />
        </li>
        <li>
          <FaLinkedinIn />
        </li>
      </ul>
    </FooterSquareStyled>
  );
};

export default FooterSquare;
