import React from "react";
import SectionContainer from "../../components/SectionContainer";
import SectionHead from "../../components/SectionHead";
import ServicesBlock from "./ServicesBlock";

const Services = () => {
  return (
    <SectionContainer id={"services"} secName={"services-sec"}>
      <SectionHead
        head={"redes"}
        text={
          "Uma conexão com a internet bem dimencionada e bem feita, é crucial em uma empresa"
        }
      />
      <ServicesBlock />
    </SectionContainer>
  );
};

export default Services;
