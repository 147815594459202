export const footerLinks = {
  usefulLinks: {
    head: "Links Úteis",
    links: ["Inicio", "Suporte", "Orçamento", "Portfolio", "Contato"],
  },
  servicesLinks: {
    head: "Principais serviços",
    links: [
      "Web Design",
      "Web Development",
      "Redes corporativas",
      "Sistemas nativos",
      "Criação de portfolios",
    ],
  },
};
