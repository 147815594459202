import {IoNewspaperOutline} from "react-icons/io5";
import { DiAndroid } from "react-icons/di";
import { DiApple } from "react-icons/di";
import { IoWifiSharp } from "react-icons/io5";
import { FiActivity } from "react-icons/fi";
import { FiHardDrive } from "react-icons/fi";
export const services = [
  {
    iconName: IoWifiSharp,
    title: "Redimencionamento Wifi",
    text: "Com experiência na área, contamos também com serviço de redimencionamento da sua rede Wifi. Com técnologia de ponta utilizando roteadores Ubiquiti, linha Unifi.",
  },
  {
    iconName: FiActivity,
    title: "Gestão de rede",
    text: "Oferecemos serviço para gestão de rede, configuração, separação e segurança de rede. Uma rede bem estruturada é essencial em uma empresa de porte médio ou grande.",
  },
  {
    iconName: FiHardDrive,
    title: "Reestruturação de rack's",
    text: "Com uma rede estruturada, segura e com seu rack de equipamentos bem organiza, e, com equipamentos de qualidade não haverá problemas em sua rede.",
  },
];
