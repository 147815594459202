import {MdOutlineLocationOn, MdOutlineEmail} from "react-icons/md";
import {BiPhoneCall} from "react-icons/bi";

export const contactInfo = [
  {
    Icon: MdOutlineLocationOn,
    title: "Nosso endereço",
    text: "Rua da Pedreira, 1505, Santa Cruz do Sul",
  },
  {
    Icon: MdOutlineEmail,
    title: "Email",
    text: "contato@milbytes.com.br",
  },
  {
    Icon: BiPhoneCall,
    title: "Contate-nos",
    text: "+55 51 9 9585-6053",
  },
];
