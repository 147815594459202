import html from "../../assets/linguagens/html.png";
import css from "../../assets/linguagens/css.png";
import php from "../../assets/linguagens/php.png";
import react from "../../assets/linguagens/react.png";
import reactnative from "../../assets/linguagens/reactnative.png"
import node from "../../assets/linguagens/node.png";
import python from "../../assets/linguagens/python.png";
import mysql from "../../assets/linguagens/mysql.png";
import postgres from "../../assets/linguagens/postgres.png";
import csharp from "../../assets/linguagens/csharp.png";
import java from "../../assets/linguagens/java.png";
import next from "../../assets/linguagens/next.png";


export const paragraphs = [
  "Profissionais dedicados 24/7.",
  "Suporte para sua hospedagem, seja ela online ou privada.",
  "Sei la o que por aqui.",
];
export const cards = [
  {
    title: "Linguagens",
    text: "Utilizamos mais de 11 linguagens de programação diferentes, buscando fluídez no código e na navegação de nossos clientes.",
    number: "11",
  },
  {
    title: "DataBase",
    text: "Contamos com mais de 30 databases espalhados pelo mundo para backups.",
    number: "+30",
  },
  {
    title: "Clientes",
    text: "Mais de 90 clientes atendidos na América Latina.",
    number: "+90",
  },
];
{/* logos das linguagens usadas*/}
export const sponsors = [
  
  html,
  python,
  php,
  css,
  react,
  reactnative,
  next,
  node,
  mysql,
  postgres,
  csharp,
  java,
  
];

{/*sponser1,
  sponser2,
  sponser3,
  sponser4,
  sponser5,
  sponser6, */}
