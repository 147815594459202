import React from "react";
import SectionContainer from "../../components/SectionContainer";
import SectionHead from "../../components/SectionHead";
import PricingBlock from "./PricingBlock";

const Pricing = () => {
  return (
    <SectionContainer id={"pricing"} secName={"pricing-sec"}>
      <SectionHead
        head={"planos"}
        text={
          "Explore nossos planos e escolha o que mais lhe satisfaça"
        }
      />
      <PricingBlock />
    </SectionContainer>
  );
};

export default Pricing;
