import React from "react";
import CustomButton from "../CustomButton";
import { PricingCardStyled } from "./pricingCard.styled";

const PricingCard = ({ bloodState, title, buttonType, alert, parcela, price, list, time}) => {
  return (
    <PricingCardStyled bloodState={bloodState} planType={alert}>
      <h3>{alert}</h3>
      <h4>
        <span className="title">{title}</span>
        <br></br>
        <sub>{parcela}</sub>
        <sup>R$</sup>
        <span>{price}</span>
        <sub>{time}</sub>
      </h4>
      <ul>
        {list.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
      <CustomButton type={buttonType} className="mb-4">
        Assinar
      </CustomButton>
    </PricingCardStyled>
  );
};

export default PricingCard;
