import React from "react";
import {Col, Image} from "react-bootstrap";
import SectionContainer from "../../components/SectionContainer";
import AboutBlocks from "./AboutBlocks";
import AboutSponsor from "./AboutSponsor";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {RowReversedStyled} from "./About.styled";
import {paragraphs} from "./aboutData";
import aboutImage from "../../assets/about.jpg";

const About = () => {
  return (
    <>
      <SectionContainer id={"about"} secName={"about-sec"}>
        <RowReversedStyled className="my-2">
          <Col lg={6} className="mb-3">
            <h3>
              Empresa com equipe profissional para melhor atender você cliente.
            </h3>
            <p className="fst-italic">
              Contamos com mais de 15 desenvolvedor de cada linguagem de programação, além de 4 gestores de projetos planejando inovações.
            </p>
            <ul>
              {paragraphs.map((par, idx) => (
                <li className="mb-3" key={idx}>
                  <AiOutlineCheckCircle
                    className="me-1"
                    size={24}
                    color={"var(--ds-blood)"}
                  />
                  {par}
                </li>
              ))}
            </ul>
            <p>
              <strong>Quer saber mais?</strong> Entre em contato conosco!
            </p>
          </Col>
          <Col lg={6} className="mb-3">
            <Image
              src={aboutImage}
              alt="about-img"
              width="100%"
              height="auto"
            />
          </Col>
        </RowReversedStyled>
        <AboutBlocks />
      </SectionContainer>
      <AboutSponsor />
    </>
  );
};

export default About;
