import {IoNewspaperOutline} from "react-icons/io5";
import { DiAndroid } from "react-icons/di";
import { DiApple } from "react-icons/di";
import { IoWifiSharp } from "react-icons/io5";
import { FiActivity } from "react-icons/fi";
import { FiHardDrive } from "react-icons/fi";
export const services = [
  {
    iconName: IoNewspaperOutline,
    title: "Desenvolvimento de landingpages e portfolios",
    text: "Criação de apresentações Web, mais conhecidas no mercado como landingpage ou LP. São páginas simples, que tendem a apenas mostrar informações básicas. Um exemplo é esta página que você esta acessando."
  },
  {
    iconName: DiAndroid,
    title: "Desenvolvimento de aplicativos nativos para sistemas Android",
    text: "Criação de sistemas Android, criado na melhor linguagem do mercado. Aplicativos responsívos para qualquer tela, com navegação ultra rápida e estilizado como o cliente deseja.",
  },
  {
    iconName: DiApple,
    title: "Desenvolvimento de aplicativos nativos para sistemas IOS",
    text: "Criação de sistemas Android, criado na melhor linguagem do mercado. Aplicativos responsívos para qualquer tela, com navegação ultra rápida e estilizado como o cliente deseja.",
  },
];
